import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MockKeycloakService {
    init() {
        console.log('[KEYCLOAK] Mocked Keycloak call');
        return Promise.resolve(true);
    }

    getKeycloakInstance() {
        return {
            loadUserInfo: () => {
                let callback: any;
                Promise.resolve().then(() => {
                    callback({
                        username: 'ldechamps',
                        name: 'loic dechamps',
                        mail: 'loic@webcapsule.io',
                    });
                });
                return { success: (fn) => (callback = fn) };
            },
        } as any;
    }

    getUsername() {
        return 'loic.d@webcapsule.io';
    }

    login() {}
    logout() {}
}
