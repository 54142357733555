<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'menu-item-list'">
        <button
            (click)="onClick($event)"
            [disabled]="isDisabled || isDisabledByRole"
            [matTooltip]="tooltipWithAut"
            [matTooltipDisabled]="false"
            mat-menu-item
        >
            <div *ngTemplateOutlet="content"></div>
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'icon-button'">
        <button
            (click)="onClick($event)"
            [disabled]="isDisabled || isDisabledByRole"
            [matTooltip]="tooltipWithAut"
            [matTooltipDisabled]="false"
            mat-icon-button
            class="icon-button"
        >
            <div *ngTemplateOutlet="content" class="icon-container"></div>
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'menu-button'">
        <button
            (click)="onClick($event)"
            [disabled]="isDisabled || isDisabledByRole"
            [matTooltip]="tooltipWithAut"
            [matTooltipDisabled]="false"
            class="menu-button"
            mat-button
        >
            <div *ngTemplateOutlet="content"></div>
        </button>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <button
            (click)="onClick($event)"
            [disabled]="isDisabled || isDisabledByRole"
            [ngClass]="styles[type] + ' ' + (selected ? 'selected' : '')"
            class="wbce"
            matRipple
            [matRippleColor]="rippleColor[type]"
            [matRippleCentered]="true"
            [matRippleDisabled]="isDisabled"
            [matTooltip]="tooltipWithAut"
            [matTooltipDisabled]="false"
        >
            <div *ngTemplateOutlet="content"></div>
        </button>
    </ng-container>
</ng-container>

<ng-template #content>
    <ng-content class="content"></ng-content>
</ng-template>
