export enum contextes {
    GLOBAL = 'global',
    PRODUCTION = 'prod',
    STAGING = 'staging',
    PREVIEW = 'preview',
    DEV = 'dev', //en general, correspond a un branchement avec le saas des outils noCode
    LOCAL = 'local',
    PRODUCTION_LOCAL = 'prod-local', //we build in local but we deploy to prod
}

export const contexteShape = new Proxy(
    {
        [contextes.STAGING]: 'cercle',
        [contextes.PRODUCTION]: 'triangle',
    },
    {
        get(target, p: string, receiver) {
            if (!target[p]) {
                return 'cercle';
            }
            return target[p];
        },
    }
);

export const contexteAffichage = new Proxy(
    {
        [contextes.STAGING]: 'Staging',
        [contextes.PRODUCTION]: 'Production',
    },
    {
        get(target, p: string, receiver) {
            if (!target[p]) {
                return `${p[0].toUpperCase()}${p.slice(1)}`;
            }
            return target[p];
        },
    }
);

export enum sizeConfig {
    STAGING = 'staging',
    PRODUCTION = 'prod',
}

export const listeContextes = [contextes.PRODUCTION, contextes.STAGING];
