import { inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';
import { MockKeycloakService } from './keycloak.service-mock';

import { Observable } from 'rxjs';
import { KeycloakServiceContext } from './keycloak.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardMock {
    protected readonly router = inject(Router);
    protected readonly keycloak = inject(KeycloakServiceContext);
    private readonly auth = inject(AuthService);

    constructor() {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.auth.isLoggedIn(); //maybe reload user otherwise
    }
}
