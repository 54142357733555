import { HttpBackend, HttpClient } from '@angular/common/http';
import { inject, Injectable, Provider } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
    KeycloakAuthGuard,
    KeycloakOptions,
    KeycloakService,
} from 'keycloak-angular';
import { MockKeycloakService } from './keycloak.service-mock';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { AuthGuardMock } from './auth.guard.keycloak-mock';
import KeyCloakCtr from '@keycloak/keycloak-admin-client';
import ClientRepresentation from '@keycloak/keycloak-admin-client/lib/defs/clientRepresentation.js';
import ResourceRepresentation from '@keycloak/keycloak-admin-client/lib/defs/resourceRepresentation.js';
import { KeycloakConfig } from 'keycloak-js';
import { contextes } from '../enums/contextes';
import ScopeRepresentation from '@keycloak/keycloak-admin-client/lib/defs/scopeRepresentation';
import RoleRepresentation from '@keycloak/keycloak-admin-client/lib/defs/roleRepresentation';

let BaseKeycloak = KeycloakService;
let BaseKeycloakGuard = KeycloakAuthGuard;
if (environment.mock) {
    BaseKeycloak = MockKeycloakService as any;
    BaseKeycloakGuard = AuthGuardMock as any;
}

export enum keycloakClientNames {
    WBCE_ADMIN = 'wbce-admin',
    WBCE_FRONT = 'wbce-front',
    RESOURCE_SERVER = 'resource-server',
    REALM_SERVER = 'realm-management',
}

export enum wbceKeycloakScopes {
    //the verb of permissions
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export enum userManagementRoles {
    ADMIN = 'realm-admin',
    READER = 'query-users',
}

export enum realmDefaultKeycloalRoleNames {
    ADMIN = 'realm-admin',
    READER = 'view-realm',
}

@Injectable({
    providedIn: 'root',
})
export class KeycloakServiceContext extends BaseKeycloak {
    kcAdminClient: KeyCloakCtr;
    endpoints: {
        token_endpoint: string;
        authorization_endpoint: string;
        console_url: string;
        realm_admin_url: string;
    };

    clientCache: { [key: string]: ClientRepresentation } = {};

    private http = inject(HttpClient);
    private httpHandler = inject(HttpBackend);

    constructor() {
        super();
    }

    init(options?: KeycloakOptions): Promise<boolean> {
        this.kcAdminClient = new KeyCloakCtr({
            baseUrl: (options.config as KeycloakConfig).url,
            realmName: (options.config as KeycloakConfig).realm,
        });
        return super
            .init(options)
            .then((result) => {
                const httpClient = new HttpClient(this.httpHandler); //without the interceptor because we need to bypass auth on this
                return httpClient
                    .get(
                        `${this.kcAdminClient.baseUrl}/realms/webcapsule/.well-known/openid-configuration`
                    )
                    .toPromise()
                    .then((res) => {
                        this.endpoints = res as this['endpoints'];
                        this.endpoints['console_url'] =
                            `${this.kcAdminClient.baseUrl}/admin/webcapsule/console`;
                        this.endpoints['realm_admin_url'] =
                            `${this.kcAdminClient.baseUrl}/admin/realms/webcapsule`;
                        return result;
                    });
            })
            .then(async (result) => {
                this.kcAdminClient.setAccessToken(await this.getToken());
                return result;
            });
    }

    updateToken(minValidity?: number): Promise<boolean> {
        return super.updateToken().then(async (result) => {
            this.kcAdminClient.setAccessToken(await this.getToken());
            return result;
        });
    }

    async getScopes(wbceScopes: wbceKeycloakScopes[]) {
        const result = [];
        let resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        const remoteScopes = await this.kcAdminClient.clients.listAllScopes({
            id: resourceClient.id,
            realm: 'webcapsule',
        });
        return remoteScopes.filter((s) => {
            return wbceScopes.includes(s.name as wbceKeycloakScopes);
        });
    }

    findClient(clientId: keycloakClientNames) {
        //this request can be cached
        if (this.clientCache[clientId]) {
            return this.clientCache[clientId];
        }
        return this.kcAdminClient.clients
            .find({
                realm: 'webcapsule',
                clientId,
            })
            .then((res) => {
                this.clientCache[clientId] = res[0];
                return res[0];
            });
    }

    async findResource(resourceName) {
        const resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        return this.kcAdminClient.clients
            .listResources({
                realm: 'webcapsule',
                name: resourceName,
                id: resourceClient.id,
            })
            .then((res) => {
                return res[0];
            });
    }

    async findPolicy(policyName) {
        const resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        return this.kcAdminClient.clients.findPolicyByName({
            id: resourceClient.id,
            name: policyName,
            realm: 'webcapsule',
        });
    }

    async findResources(resourceNames: string[]) {
        const resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        const result: ResourceRepresentation[] = [];
        for (const resourceName of resourceNames) {
            const resource = await this.findResource(resourceName);
            result.push(resource);
        }
        return result;
    }

    getPolicyNameForRole(roleName: string) {
        return `${roleName}-role-policy`;
    }

    getAuthzUri(clientId, permissionId) {
        return `${this.endpoints.realm_admin_url}/clients/${clientId}/authz/resource-server/policy/${permissionId}`;
    }

    async findPermissionsForRole(roleName: string) {
        const policyName = this.getPolicyNameForRole(roleName);
        let policy = await this.findPolicy(policyName);
        if (!policy) {
            await this.upsertRolePolicy(roleName);
        }
        const resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        return this.kcAdminClient.clients.listDependentPolicies({
            id: resourceClient.id,
            policyId: policy.id,
            realm: 'webcapsule',
        });
    }

    async findResourceForPermission(permissionId: string) {
        const resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        const uri = `${this.getAuthzUri(resourceClient.id, permissionId)}/resources`;
        const res = (await this.http
            .get(uri)
            .toPromise()) as ResourceRepresentation[];
        return this.kcAdminClient.clients.getResource({
            id: resourceClient.id,
            resourceId: res[0]._id,
            realm: 'webcapsule',
        });
    }

    async findScopesForPermission(permissionId) {
        const resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        const uri = `${this.getAuthzUri(resourceClient.id, permissionId)}/scopes`;
        const res = (await this.http
            .get(uri)
            .toPromise()) as ScopeRepresentation[];
        return res;
    }

    findUsersForRole(roleName: string) {
        return this.kcAdminClient.roles.findUsersWithRole({
            realm: 'webcapsule',
            name: roleName,
        });
    }

    findRolesForUser(userId: string) {
        return this.kcAdminClient.users.listRoleMappings({
            id: userId,
            realm: 'webcapsule',
        });
    }

    async upsertWbceScopes() {
        let resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        const remoteScopes = await this.kcAdminClient.clients.listAllScopes({
            id: resourceClient.id,
            realm: 'webcapsule',
        });
        for (const keyScope in wbceKeycloakScopes) {
            const remoteScopeIndex = remoteScopes.findIndex((s) => {
                s.name === wbceKeycloakScopes[keyScope];
            });
            if (remoteScopeIndex) {
                const remoteScope = remoteScopes.splice(remoteScopeIndex, 1)[0];
                await this.kcAdminClient.clients.updateAuthorizationScope(
                    {
                        id: resourceClient.id,
                        scopeId: remoteScope.id,
                        realm: 'webcapsule',
                    },
                    {
                        name: wbceKeycloakScopes[keyScope],
                    }
                );
            } else {
                await this.kcAdminClient.clients.createAuthorizationScope(
                    {
                        id: resourceClient.id,
                        realm: 'webcapsule',
                    },
                    {
                        name: wbceKeycloakScopes[keyScope],
                    }
                );
            }
            for (const remoteScope of remoteScopes) {
                await this.kcAdminClient.clients.delAuthorizationScope({
                    id: resourceClient.id,
                    scopeId: remoteScope.id,
                    realm: 'webcapsule',
                });
            }
        }
    }

    async upsertRolePolicy(roleName) {
        let resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        const role = await this.kcAdminClient.roles.findOneByName({
            name: roleName,
            realm: 'webcapsule',
        });
        const rolePolicyName = this.getPolicyNameForRole(roleName);
        const rolePolicy = await this.kcAdminClient.clients.findPolicyByName({
            id: resourceClient.id,
            realm: 'webcapsule',
            name: rolePolicyName,
        });
        if (!rolePolicy) {
            return await this.kcAdminClient.clients.createPolicy(
                {
                    id: resourceClient.id,
                    realm: 'webcapsule',
                    type: 'role',
                },
                {
                    name: rolePolicyName,
                    roles: [
                        {
                            id: role.id,
                            required: true,
                        },
                    ],
                }
            );
        }
        await this.kcAdminClient.clients.updatePolicy(
            {
                id: resourceClient.id,
                realm: 'webcapsule',
                type: 'role',
                policyId: rolePolicy.id,
            },
            {
                name: rolePolicyName,
                roles: [
                    {
                        id: role.id,
                        required: true,
                    },
                ],
            }
        );
        return rolePolicy;
    }

    async upsertPermission(
        roleName: string,
        projectId: string,
        ctxt: contextes,
        wbceScopes: wbceKeycloakScopes[]
    ) {
        const resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        let uri = `/projects`;
        if (projectId && ctxt) {
            uri = `${uri}/id/${projectId}/contextes/${ctxt}/*`;
        } else if (projectId && !ctxt) {
            uri = `${uri}/id/${projectId}/*`;
        } else if (!projectId && ctxt) {
            uri = `${uri}/id/{id}/contextes/${ctxt}/*`;
        } else {
            uri = `${uri}/*`;
        }
        //we could also list all resources by attributes
        const resources = await this.kcAdminClient.clients.listResources({
            id: resourceClient.id,
            realm: 'webcapsule',
            uri,
        });
        const resource = resources[0];
        const rolePolicy = await this.upsertRolePolicy(roleName);
        const rolePermissionName = `${roleName}-${resource.name}-permission`;
        const permissions = await this.kcAdminClient.clients.findPermissions({
            id: resourceClient.id,
            realm: 'webcapsule',
            name: rolePermissionName,
        });
        const permission = permissions[0];
        const scopes = await this.getScopes(wbceScopes);
        if (!permission) {
            return await this.kcAdminClient.clients.createPermission(
                {
                    id: resourceClient.id,
                    type: 'scope',
                    realm: 'webcapsule',
                },
                {
                    name: rolePermissionName,
                    policies: [rolePolicy.id],
                    scopes: scopes.map((s) => s.id),
                    resources: [resource._id],
                }
            );
        }
        await this.kcAdminClient.clients.updatePermission(
            {
                id: resourceClient.id,
                type: 'scope',
                realm: 'webcapsule',
                permissionId: permission.id,
            },
            {
                name: rolePermissionName,
                policies: [rolePolicy.id],
                scopes: scopes.map((s) => s.id),
                resources: [resource._id],
            }
        );
        return permission;
    }

    async deletePermission(permId: string) {
        const resourceClient = await this.findClient(
            keycloakClientNames.RESOURCE_SERVER
        );
        return this.kcAdminClient.clients.delPermission({
            id: resourceClient.id,
            permissionId: permId,
            realm: 'webcapsule',
            type: 'scope',
        });
    }

    async upsertRole(
        roleName: string,
        opts: {
            description?: string;
            creationOnly?: boolean;
        } = {}
    ) {
        const role = await this.kcAdminClient.roles.findOneByName({
            name: roleName,
            realm: 'webcapsule',
        });
        if (role) {
            if (opts.creationOnly) {
                throw new Error('role already exists');
            }
            await this.kcAdminClient.roles.updateByName(
                {
                    name: roleName,
                    realm: 'webcapsule',
                },
                {
                    name: roleName,
                    description: opts.description,
                    attributes: {
                        'managed-by': ['wbce'],
                    },
                }
            );
        } else {
            await this.kcAdminClient.roles.create({
                realm: 'webcapsule',
                name: roleName,
                description: opts.description,
                attributes: {
                    'managed-by': ['wbce'],
                },
            });
        }
        await this.upsertRolePolicy(roleName);
        return this.kcAdminClient.roles.findOneByName({
            name: roleName,
            realm: 'webcapsule',
        });
    }

    async deleteRole(roleName: string) {
        await this.kcAdminClient.roles.delByName({
            name: roleName,
            realm: 'webcapsule',
        });
    }

    async addUserToRole(
        userId: string,
        roleName: string,
        clientName?: keycloakClientNames
    ) {
        if (clientName) {
            const client = await this.findClient(clientName);
            const role = await this.kcAdminClient.clients.findRole({
                realm: 'webcapsule',
                roleName,
                id: client.id,
            });
            await this.kcAdminClient.users.addClientRoleMappings({
                realm: 'webcapsule',
                id: userId,
                clientUniqueId: client.id,
                roles: [
                    {
                        id: role.id,
                        name: role.name,
                    },
                ],
            });
        } else {
            const role = await this.kcAdminClient.roles.findOneByName({
                name: roleName,
                realm: 'webcapsule',
            });
            await this.kcAdminClient.users.addRealmRoleMappings({
                realm: 'webcapsule',
                id: userId,
                roles: [
                    {
                        id: role.id,
                        name: role.name,
                    },
                ],
            });
        }
    }

    async removeUserToRole(
        userId: string,
        roleName: string,
        clientName?: keycloakClientNames
    ) {
        if (clientName) {
            const client = await this.findClient(clientName);
            const role = await this.kcAdminClient.clients.findRole({
                realm: 'webcapsule',
                roleName,
                id: client.id,
            });
            await this.kcAdminClient.users.delClientRoleMappings({
                realm: 'webcapsule',
                id: userId,
                clientUniqueId: client.id,
                roles: [
                    {
                        id: role.id,
                        name: role.name,
                    },
                ],
            });
        } else {
            const role = await this.kcAdminClient.roles.findOneByName({
                name: roleName,
                realm: 'webcapsule',
            });
            await this.kcAdminClient.users.delRealmRoleMappings({
                realm: 'webcapsule',
                id: userId,
                roles: [
                    {
                        id: role.id,
                        name: role.name,
                    },
                ],
            });
        }
    }

    listUsers() {
        return Promise.resolve().then(() => {
            return this.kcAdminClient.users.find({
                realm: 'webcapsule',
            });
        });
    }

    listRoles() {
        return Promise.resolve().then(() => {
            return this.kcAdminClient.roles.find({
                realm: 'webcapsule',
            });
        });
    }

    async findRolesLinkedToRoleA(roleAId: string) {
        return await this.kcAdminClient.roles.getCompositeRoles({
            realm: 'webcapsule',
            id: roleAId,
        });
    }

    async linkRoleBToRoleA(roleAId: string, roleB: RoleRepresentation) {
        await this.kcAdminClient.roles.createComposite(
            {
                roleId: roleAId,
                realm: 'webcapsule',
            },
            [roleB]
        );
    }

    async unlinkRoleBToRoleA(roleAId: string, roleB: RoleRepresentation) {
        await this.kcAdminClient.roles.delCompositeRoles(
            {
                id: roleAId,
                realm: 'webcapsule',
            },
            [roleB]
        );
    }

    findUserWithMail(email: string) {
        return Promise.resolve()
            .then(() => {
                return this.kcAdminClient.users.find({
                    realm: 'webcapsule',
                    email,
                    exact: true,
                });
            })
            .then((users) => {
                return users[0];
            });
    }

    createUser(email: string) {
        let res: { id: string };
        return Promise.resolve().then(() => {
            return this.kcAdminClient.users.create({
                realm: 'webcapsule',
                email,
                username: email,
                enabled: true,
                requiredActions: [
                    'UPDATE_PROFILE',
                    'VERIFY_EMAIL',
                    'UPDATE_PASSWORD',
                ],
            });
        });
    }

    deleteUser(userId: string) {
        return this.kcAdminClient.users.del({
            realm: 'webcapsule',
            id: userId,
        });
    }

    sendInvitationEmail(userId) {
        return this.kcAdminClient.users.executeActionsEmail({
            realm: 'webcapsule',
            id: userId,
            clientId: 'wbce-admin',
            actions: ['UPDATE_PROFILE', 'VERIFY_EMAIL', 'UPDATE_PASSWORD'],
        });
    }

    async checkUserHasRight(
        req: Request,
        config: {
            scope: wbceKeycloakScopes;
            projectId?: string;
            projectName?: string;
            context: contextes;
        }
    ) {
        //cf : https://www.keycloak.org/docs/latest/authorization_services/index.html#_service_overview
        //this is an oauth2 route
        //but we cannot use directly this.client because the openid-client doesn't allow this call (or doesn't allow it easily)
        let uri = '/projects';
        if (config.projectId) {
            uri = `${uri}/id/${config.projectId}`;
        } else {
            uri = `${uri}/name/${config.projectName}`;
        }
        if (config.context) {
            uri = `${uri}/contextes/${config.context}`;
        }

        const data = new URLSearchParams();

        data.append(
            'grant_type',
            'urn:ietf:params:oauth:grant-type:uma-ticket'
        );
        data.append('permission_resource_format', 'uri');
        data.append('permission_resource_matching_uri', 'true');
        data.append('permission', `${uri}#${config.scope}`);
        data.append('audience', 'resource-server');
        data.append('response_mode', 'permissions');

        return this.http.post(this.endpoints.token_endpoint, data);
    }
}

export class KeycloakAuthGuardContext extends BaseKeycloakGuard {
    constructor(
        protected readonly router,
        protected readonly keycloak: KeycloakServiceContext
    ) {
        super(router, keycloak);
    }

    isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        return Promise.resolve(true);
    }
}
